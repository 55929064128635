<template>
  <div class="bind">
    <PageNav :showBack="false" title="绑定账号" />
    <div v-if="userInfo" class="bind_content">
      <p>当前登录账号：{{userInfo.nickName}}</p>
      <p>手机号：{{userInfo.phone}}</p>
      <van-button round block type="info" @click="onBind">立即绑定</van-button>
      <p></p>
      <van-button round block plain type="info" @click="onLogin">更换账号</van-button>
    </div>
    <div v-else class="bind_content">
      <p>请先登录</p>
      <van-button round block type="info" @click="onLogin">去登录</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PageNav from '@/components/PageNav';
import { Form, Field, Toast, Dialog } from 'vant';
import utils from '@/common/utils';
import User from '@/common/user';
import Fetch from '@/common/fetch';
import { BindWeChat } from '@/common/interface';

Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);

export default {
  name: "GzhBind",
  components: { PageNav },
  data() {
    return {
      userInfo: User.getInfo(),
    };
  },

  methods: {
    onLogin() {
      this.$router.push('/login')
    },

    onBind() {
      const code = utils.getQueryString('code');
      const params = {
        code,
      };

      Fetch.post(BindWeChat, params).then(() => {
        Dialog.alert({
          message: '绑定成功',
        }).then(() => {
          this.$router.replace('/');
        });
      }).catch((err) => {
        const { msg } = err;
        Toast(msg);
      });
    },
  },
}
</script>

<style lang="scss" scoped>
body {
  background: #fff;
}
.bind_content {
  position: relative;
  padding: 15px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.bind_content p {
  margin-bottom: 1.5em;
}
</style>
